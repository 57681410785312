.site-header {
  background: var(--header-background-color);
  border-bottom: 4px solid var(--custom-color);
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 10px 0;

  .header-log {
    img {
      max-width: 130px;
    }

    max-width: fit-content;
    cursor: pointer;
  }

  .header-wrapper {
    align-items: center;
    justify-content: space-between;

    .navigation-wrapper {
      .navigation {
        .nav-list {
          justify-content: center;

          .nav-items {
            margin: 0 5px;
            font-weight: 600;
            padding: 10px 20px;
            border-bottom: 4px solid transparent;
            transition: all 0.3s ease;

            &:hover,
            &.active {
              cursor: pointer;
              border-bottom: 4px solid var(--custom-color);
              background: var(--icon-hover);
              transition: all 0.3s ease;
              box-shadow: 2px 1px 2px -1px #70825d8a;

            }

            a {
              color: var(--black-color);
              font-size: 18px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .header-bar-icons {
    justify-content: end;

    .user-icon,
    .search {
      margin: 10px;

      &:hover {
        background: var(--icon-hover);
        box-shadow: 1px 1px 2px 1px var(--custom-color);
      }
    }

    .user-icon,
    .user-icon-mobile-view {
      display: flex;
      gap: 8px;
      font-weight: 700;
      background: rgb(173 199 144);
      padding: 15px 30px;
      cursor: pointer;
      box-shadow: 0.5px 1px 3px 0px var(--custom-color);
      text-transform: uppercase;
      letter-spacing: 0.143em;
      border: unset;
    }

    a {
      color: unset;
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .dropdown-toggle::after {
      display: none;
    }
  }

  .Bar-icon,
  .mobile-view-bar {
    display: none;
  }

  .dropdown-menu {
    border-radius: unset;
    width: 100%;
  }
}

.mobile-sider {
  background-color: var(--mobile-sider-color);

  .navigation {
    .nav-list {
      flex-direction: column;

      .nav-items {
        font-weight: 600;
        margin-bottom: 5px;
        padding: 5px 15px;
        border-left: 2px solid transparent;

        &:hover,
        &:active {
          border-left: 2px solid var(--custom-color);
          color: var(--custom-color);

        }

        a {
          color: var(--black-color);
          font-weight: 500;
          font-size: 16px;
          border-bottom: 2px solid transparent;

          &:hover,
          &:active {
            border-bottom: 2px solid var(--custom-color);
            color: var(--custom-color);
            font-weight: 500;
          }
        }

        &:hover,
        &:active {
          background-color: var(--white-color);
        }
      }
    }
  }
}

.offcanvas.offcanvas-start {
  max-width: fit-content;
}

.dropdown-item {
  font-weight: 500;

  &.active,
  &:active {
    background-color: var(--header-background-color);
  }
}

.mobile-view-bar {

  .dropdown-menu,
  .dashboard-view-bar .dropdown-menu {
    border-radius: 0;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.offcanvas-backdrop,
.mobile-view-nav {
  display: none;

  .offcanvas-header {
    background-color: var(--header-background-color);
    gap: 5px;
  }
}

@media screen and (max-width: 1200px) {
  .site-header {
    .user-name {
      max-width: fit-content;
      width: 140px;
      padding: 0;
      overflow: hidden;
      position: relative;
      display: inline-block;
      margin: 0 5px 0 5px;
      text-align: center;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #000;
    }
    .header-wrapper .navigation-wrapper .navigation .nav-list .nav-items {
      padding: 10px 10px;
    }
  }
}

@media screen and (max-width: 1080px) {
  .site-header {

    .header-wrapper .logo {
      max-width: fit-content;
    }
    .header-wrapper .navigation-wrapper .navigation .nav-list .nav-items {
      padding: 10px;
    }
  }
  .site-header .header-wrapper .navigation-wrapper .navigation .nav-list .nav-items a {
    white-space:nowrap;
    text-overflow:ellipsis;
    max-width:130px;
    overflow:hidden;
    vertical-align: middle;

}
}

@media screen and (max-width: 991px) {
  .site-header {
    .header-bar-icons {
      .user-icon {
        padding: 15px 20px;
        font-size: 14px;
        font-size: 14px;
      }

      .search,
      .header-wrapper .navigation-wrapper .navigation .nav-list .nav-items {
        padding: 10px;
      }
    
    }
    .header-log svg,
    .header-log img {
      max-width: 100px;
    }

    .header-wrapper .logo {
      max-width: fit-content;
    }

    .header-wrapper .navigation-wrapper .navigation .nav-list .nav-items a {
      font-size: 16px;
    }
  }
  .site-header .header-bar-icons .user-icon{
    margin: 0;
  }
  .header-wrapper .navigation-wrapper .navigation .nav-list .nav-items {
    padding: 10px 8px;
    font-size: 14px;
  }
  .site-header .header-wrapper .navigation-wrapper .navigation .nav-list {
    justify-content: start;
    align-items: center;
}
}

@media screen and (max-width: 767px) {

  .offcanvas-backdrop,
  .mobile-view-nav {
    display: block;
  }

  .dashboard-view-bar,
  .navigation-wrapper .navigation {
    display: none;
  }

  .site-header {
    .header-log svg,
    .header-log img {
      max-width: 90px;
    }

    .header-bar-icons {
      .user-icon {
        padding: 10px 20px;
        font-size: 14px;
        display: none;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .user-icon-mobile-view.dropdown-toggle::after {
        display: none;
      }

      .user-icon-mobile-view {
        border-radius: unset;
        min-height: unset;
        padding: 10px;
      }
    }

    .Bar-icon {
      display: block;
    }

    .header-log {
      margin: auto;
    }

    .mobile-view-bar {
      display: block;
    }

    .mobile-view-nav {
      .nav-logo {
        max-width: 70px;
      }

      .offcanvas-header {
        gap: 5px;

        .offcanvas-title h6 {
          font-size: 60px;
        }
      }

      .btn-close {
        box-shadow: 0.5px 1px 3px 0px var(--custom-color);
        border: 1px solid var(--black-color);
        border-radius: 0;
      }
    }
  }

  .navigation-wrapper {
    order: -1;
  }
}
@media (max-width:600px) {
  .header-log {
  max-width: 144px;
}
}
@media screen and (max-width: 475px) {
  .site-header {
    .header-bar-icons {
      .user-icon {
        padding: 10px 15px;
        font-size: 11px;
      }
    }
  }

  .offcanvas-header {
    gap: 5px;

    .offcanvas-title h6 {
      font-size: 14px;
    }
  }
}